:root {
/* COLORS */

  --primary-color:#004977;
  --secondary-color: #28B17D;
  --element-background-color:#fff;
  --header-color:#fff;
  --tag-color:#E7F1FC;
  --text-color:#2D3047;
  /* OTHERS*/
  --shadow: 0 1px 2px rgba(57,62,65,.2);
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f2f2f2;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAADlJREFUOBFj/PTp038GCgATBXrBWkcNYGCgbRh8+/aNAYTxAYpdwILPdC4uLnzSYDmKXTBqABUSEgB4HAnZF5CXGwAAAABJRU5ErkJggg==);
  color: #2D3047;
  color: var(--text-color);
 
  
}
#root {
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.canvas-view {
  display:grid;
  grid-template-columns:100% ;
  grid-template-rows: auto;
  grid-gap:16px;
  gap:16px;
  justify-content:center;
  grid-template-areas:
      "customerSegments" 
      "valueProposition"
      "channels"
      "customerRelationship"
      "revenueStreams"
      "keyActivities"
      "keyResources"
      "partners"
      "costStructure";
     
    
}

.gird-list {
  display: grid;
  grid-template-columns:100%;
  grid-gap:16px;
  gap:16px;
  margin-top:20px;
  justify-content: center;
}

.canvas-exprot {
  padding: 24px;
  display:grid;
  grid-template-rows: auto;
  grid-gap:16px;
  gap:16px;
  justify-content:center;
  grid-template-columns:repeat( 10,1fr );
  grid-template-areas: 
    "partners partners keyActivities keyActivities valueProposition valueProposition customerRelationship customerRelationship customerSegments customerSegments"
    "partners partners keyResources keyResources valueProposition valueProposition channels channels customerSegments customerSegments"
    "costStructure costStructure costStructure costStructure costStructure revenueStreams revenueStreams revenueStreams revenueStreams revenueStreams";
  
}

.DraftEditor-root h2 {
  margin:0;
}

@media only screen and (min-width:700px){
  .canvas-view {
    
    grid-template-columns: 45% 45%;
    grid-template-areas: 
      "customerSegments valueProposition"
      "channels customerRelationship"
      "revenueStreams revenueStreams"
      "keyActivities keyResources"
      "partners partners"
      "costStructure costStructure";
  }
  .gird-list {
    grid-template-columns:40% 40%;

  }
}

@media only screen and (min-width:900px){
  .canvas-view {
    /* repeat( auto-fit, minmax(160px, 1fr) ) */
    grid-template-columns:repeat( 10,1fr );
    grid-template-areas: 
      "partners partners keyActivities keyActivities valueProposition valueProposition customerRelationship customerRelationship customerSegments customerSegments"
      "partners partners keyResources keyResources valueProposition valueProposition channels channels customerSegments customerSegments"
      "costStructure costStructure costStructure costStructure costStructure revenueStreams revenueStreams revenueStreams revenueStreams revenueStreams";
    
  }
  .gird-list {
    grid-template-columns:30% 30% 30%;

  }
}


